import API from '@/api/teacher/group/unpublishGrade';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
  name: "unpublishGrade",
  components: {},
  inject: ['reload'],
  data() {
    return {
      // 是否展示成绩列表
      scoreTableDataShow: false,
      // 成绩列表数据
      scoreTableData: [],
      // 各个项目成绩列表数据
      scoreTableTitleList: [],
      // 一页显示多少条
      scorePageSize: 10,
      // 总条数
      scoreTotal: 0,
      // 页码
      scorePage: 1,
      // 搜索关键词
      search: '',
      // 发布成绩弹窗
      rightStudentDialogVisible: false,
      // 弹窗标识
      dialogSign :'',
      //复制PDF弹窗标识
      openCopyDialogVisable:false,
      // 复制顺序
      copySign:"1",
      // 复制数量
      copyNum:'',
      // pdf链接
      // pdfPath:'',
    };
  },
  created() {
    //  获取学生列表数据
    this.getstudentslist();
  },
  methods: {
    /**
     * @name: 复制PDF链接
     * @author: camellia
     * @date: 2024-01-08
     */
    copyDDFLink()
    {
      let self = this;
      let data = {
        group_id: this.$route.query.group_id,
        num:self.copyNum,
        sign:self.copySign,
      };
      nprogressStart();
      self.$Loading.show();
      API.copyPDFLink(data)
        .then((result) => {
          if (result.code < 0) 
          {
            nprogressClose();
            self.$Loading.hide();
            self.openCopyDialogVisable = false;
            this.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'warning',
              // 延时时间
              displayTime: 1500
            })
          }
          else
          {
            navigator.clipboard.writeText(result.link)
            .then(() => {
              this.$Tips({
                // 消息提示内容
                message: '已复制到剪切板',
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'success',
                // 消失时间
                displayTime: 1500
              });
            })
          }
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
    },
    /**
     * @name: 打开复制PDF链接弹窗
     * @author: camellia
     * @date: 2024-01-08
     */
    openCopyDialog()
    {
      this.openCopyDialogVisable = true;
    },
    /**
     * @name: 显示发布成绩弹窗
     * @author: lanxinyu
     * @date: 2021-08-10
     */
    showpublishDialog(sign='') {
      var self = this;
      self.dialogSign = sign;
      self.rightStudentDialogVisible = true
    },
    /**
     * @name: 发布成绩
     * @author: lanxinyu
     * @date: 2021-08-10
     */
    publish() {
      let self = this;
      let data = {
        group_id: this.$route.query.group_id,
        handleSign: self.dialogSign,
      };
      nprogressStart();
      self.$Loading.show();
      API.markRelease(data)
        .then((result) => {
          self.rightStudentDialogVisible = false;
          if (result.code > 0) 
          {
            nprogressClose();
            self.$Loading.hide();
            self.rightStudentDialogVisible = false;
            self.getstudentslist();
            this.$Tips({
              // 消息提示内容
              message: result.msg,
              // 消息提示类型（success-成功,warning-警告/失败）
              messageType: 'success',
              // 延时时间
              displayTime: 1500
            })
          }
          window.location.reload();
          // 关闭loading
          nprogressClose();
          self.$Loading.hide();
        })
      
    },
    /**
     * @name: 获取学生列表数据
     * @author: lanxinyu
     * @date: 2021-08-10
     */
    getstudentslist() 
    {
      try 
      {
        let self = this;
        let data = {
          group_id: this.$route.query.group_id,
          name: this.search,
          page: self.scorePage,
          pagesize: 10,
        }
        nprogressStart();
        self.$Loading.show();
        API.showRelease(data)
          .then((result) => {
            nprogressClose();
            self.$Loading.hide();
            if (result.code > 0) 
            {
              self.scoreTableTitleList = result.proarr;
              self.scoreTableTitleList?.forEach((item) => {
                if (item.name.substring(item.name.indexOf("*") + 1).substring(0, item.name.substring(item.name.indexOf("*") + 1).indexOf("(")) > 1) {
                  item.checked = true
                }
                else {
                  item.checked = false
                }
              })
              self.scoreTableData = result.releasearr;
              self.studentListTableData = result.releasearr;
              self.scoreTotal = result.total;
              self.scoreTableDataShow = true
            }
            else 
            {
              self.$Tips({
                // 消息提示内容
                message: result.msg,
                // 消息提示类型（success-成功,warning-警告/失败）
                messageType: 'warning',
                displayTime: 1500
              })
            }
          })
      }
      catch (error) 
      {
        self.$Tips({
          // 消息提示内容
          message: '系统错误',
          // 消息提示类型（success-成功,warning-警告/失败）
          messageType: 'warning',
          displayTime: 1500
        })
      }
    },
    scorePageChange(val) 
    {
      const self = this;
      self.scorePage = val;
      self.getstudentslist();
    },
    /**
     * @name: 成绩搜索学生
     * @author: camellia
     * @date: 2021-04-06
     */
    searchstudent() 
    {
      this.scorePage = 1;
      this.getstudentslist();
    },
  },
};