<template>
  <div class="unpublishGrade">
    <el-card>
      <!-- 添加按钮 -->
      <div style="width:100%;height:50px;line-height:50px;" class="clearFix">
        <!-- <div class="searchStudent float_left"> -->
          <span class="searchStudent " style="width:83%">
            <el-button class="themeButton floatLeft margin" @click="showpublishDialog('push')">发布</el-button>
            <el-input
              class="floatLeft marginInput "
              clearable
              placeholder="请输入学号或姓名"
              v-model="search"
              @clear="getstudentslist"
            >
              <el-button slot="append" class="el-icon-search" @click="searchstudent"></el-button>
            </el-input>
          </span>
        <!-- </div>
        <div> -->
          <span >
            <el-button class="themeButton floatLeft margin" @click="openCopyDialog()">复制PDF链接</el-button>
          </span>
          <span style="margin-left: 1%;">
            <el-button class="themeButton floatLeft margin"  @click="showpublishDialog('clear')">撤销发布</el-button>
          </span>
        <!-- </div> -->
      </div>
      <!-- 学生列表表格 -->
      <el-table :data="scoreTableData" stripe style="width: 100%" v-show="scoreTableDataShow">
        <!-- 序号 -->
        <el-table-column fixed="left" prop="key" label="序号" sortable></el-table-column>
        <!-- 学号 -->
        <el-table-column width="150" fixed="left" prop="personal_id" label="学号" sortable></el-table-column>
        <!-- 姓名 -->
        <el-table-column fixed="left" prop="name" label="姓名"></el-table-column>
        <!-- 项目标题（循环） -->
        <el-table-column
          width="180"
          v-for="item in scoreTableTitleList"
          :prop="item.id"
          :key="item.id"
        >
          <template slot="header" slot-scope="scope">
            <div>{{item.name.substring(0,item.name.indexOf("*"))}}</div>
            <div
              v-if="item.checked"
            >{{"权重: " + (item.name.substring(item.name.indexOf("*")+1).substring(0,item.name.substring(item.name.indexOf("*")+1).indexOf("("))-0).toFixed(0)+ "%"+"学时:"+item.name.substring(item.name.indexOf("*")+1).substring(item.name.substring(item.name.indexOf("*")+1).indexOf("("))}}</div>
            <div
              v-else
            >{{"权重: " + (item.name.substring(item.name.indexOf("*")+1).substring(0,item.name.substring(item.name.indexOf("*")+1).indexOf("("))*100).toFixed(0)+ "%"+"学时:"+item.name.substring(item.name.indexOf("*")+1).substring(item.name.substring(item.name.indexOf("*")+1).indexOf("("))}}</div>
          </template>
        </el-table-column>
        <!-- 总分 -->
        <el-table-column fixed="right" prop="final_score" label="总分"></el-table-column>.
        <!-- 学时 -->
        <el-table-column fixed="right" prop="sumhours" label="学时" sortable></el-table-column>
        <!-- 通过 -->
        <el-table-column fixed="right" label="通过">
          <template slot-scope="scope">
            <span v-html="scope.row.sign"></span>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <div class="page-div padding-top">
        <el-pagination
          @current-change="scorePageChange"
          background
          layout="prev, pager, next"
          :current-page.sync="scorePage"
          :total="scoreTotal"
          :page-size="scorePageSize"
          v-if="scoreTotal != 0"
        ></el-pagination>
      </div>
    </el-card>
    <!-- 发布成绩弹窗 -->
    <el-dialog title="发布成绩" :visible.sync="rightStudentDialogVisible" width="30%">
      <!-- 发布成绩 -->
      <span v-if="dialogSign == 'push'">
        <h3>将覆盖上次发布的成绩！发布成绩时间较长，请耐心等待！</h3>
      </span>
      <span v-else>
        <h3>您确定撤销已发布的成绩么？</h3>
      </span>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="rightStudentDialogVisible = false">取 消</el-button>
        <el-button class="themeButton" @click="publish">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 复制PDF链接弹窗 -->
    <el-dialog title="复制PDF链接" :visible.sync="openCopyDialogVisable" width="30%">
      <div class="copy">
        <div>
          <span>复制人数：</span>
          <span><el-input v-model="copyNum" placeholder="不输入则复制所有人！" ></el-input></span>
        </div>
        <div class="sign">
          复制顺序：
          <el-radio v-model="copySign" label="1">随机</el-radio>
          <el-radio v-model="copySign" label="2">成绩由高到低</el-radio>
        </div>
      </div>
      <!-- 底部按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button class="themecancelButton" @click="openCopyDialogVisable = false">取 消</el-button>
        <el-button class="themeButton" @click="copyDDFLink">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
// 引入学生列表表格组件
import unpublishGrade from "@/assets/js/teacher/group/unpublishGrade.js";
export default {
  ...unpublishGrade
};
</script>
<style lang="scss">
.clearFix{
  span{
    display: inline-block;
  }
  .search-input{
    width:300px;
  }
  .che{
    text-align: right;
  }
}
.el-input-group--append .el-input__inner, .el-input-group__prepend {
    width:300px !important;
}
.unpublishGrade .el-input-group {
  width: 15% !important;
}
.unpublishGrade
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #0d5570;
  color: #fff;
}
.unpublishGrade .el-table--scrollable-x .el-table__body-wrapper {
  height: 60vh;
  overflow-x: auto;
}
.unpublishGrade .el-dialog {
  border-radius: 0.625vw;
}
</style>
<style lang='scss' scoped >
@import "@/assets/css/teacher/group/unpublishGrade.scss";
</style>