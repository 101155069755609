import { request } from "@/request/http";

export default {
    getPublicShowData: (data) => {
        return request("GET", `/index.php/backbase/getPublicShowData`,data);
    },

    showRelease: (data) => {
        return request("GET", `/index.php/group/showRelease`, data);
    },

    markRelease: (data) => {
        return request("GET", `/index.php/group/markRelease`, data);
    },

    copyPDFLink: (data) => {
        return request("POST", `/index.php/group/copyPDFLink`, data);
    },
};